const data = {
    "settings": {
        "template_id": 2,
        "primaryColor": "#C50000",
        "buttonColor": "#FF3434",
        "buttonBg": "#FFDCDC",
        "darkColor": "#000000",
        "seconderyColor": "#ffffff",
        "boxShadow": "0px 30px 80px 5px rgba(209, 43, 40, 0.3)",
        "bggradient": "linear-gradient(180deg, rgba(209, 43, 40, 0.09) 0%, rgba(255, 255, 255, 0.11) 100%)",
        "contactAddress": "linear-gradient(98.63deg, #FFF8F8 0%, rgba(255, 255, 255, 0) 100%)",
    },
    "location": {
        "id": 167,
        "name": "Kokuu Ramen & Sushi Bar",
        "category": "Restaurant",
        "url": "http://127.0.0.1:5500/index.html",
        "logo": "./img/v1/logo.png",
        "logo_footer": "./img/v1/logo.png",
        "banner": {
            "title": "Kokuu Ramen & Sushi Bar",
            "description": "Savor exquisite flavors and immerse yourself in a culinary paradise where ramen and sushi dreams come true!",
            "image": "./img/v1/hero_bg.webp",
            "thumbnail": "./img/v1/hero.png",
            "button_name": "Order Now",
            "button_link": "https://google.com"
        },
        "gallery": [
            {
                "original": "./img/v1/gallery/1.jpeg",
                "thumbnail": "/img/v1/gallery/1.jpeg"
            },
            {
                "original": "./img/v1/gallery/2.jpeg",
                "thumbnail": "/img/v1/gallery/2.jpeg"
            }, {
                "original": "./img/v1/gallery/3.jpeg",
                "thumbnail": "/img/v1/gallery/3.jpeg"
            }, {
                "original": "./img/v1/gallery/4.jpeg",
                "thumbnail": "/img/v1/gallery/4.jpeg"
            }, {
                "original": "./img/v1/gallery/5.jpeg",
                "thumbnail": "/img/v1/gallery/5.jpeg"
            }, {
                "original": "./img/v1/gallery/6.jpeg",
                "thumbnail": "/img/v1/gallery/6.jpeg"
            },
        ],
        "about": [
            {
                "img": "./img/v1/gallery/5.jpeg",
                "title": "Welcome to Kokuu",
                "description": "A culinary haven where the essence of Japanese cuisine is celebrated through the artful mastery of ramen and sushi. Inspired by the rich cultural significance of the Chinese term \"谷雨\" (Grain Rain), we embrace the spirit of growth, abundance, and nourishment in every aspect of our restaurant. At Kokuu, we believe in the power of food to bring people together and create memorable experiences. Just as the arrival of Grain Rain marks a pivotal time for farmers, we strive to cultivate a dining atmosphere that fosters warmth, connection, and culinary delight.",
            },
            {
                "img": "./img/v1/gallery/1.jpeg",
                "title": "",
                "description": "Our menu showcases a harmonious blend of traditional flavors and innovative techniques, paying homage to the heritage of ramen and sushi while embracing modern culinary trends. Each dish is meticulously crafted by our skilled chefs, who draw inspiration from the changing seasons and the bountiful gifts of nature. Just as Grain Rain brings life-giving rain to nourish the grains, we are committed to sourcing the freshest and highest quality ingredients for our dishes.",
            },
            {
                "img": "./img/v1/gallery/3.jpeg",
                "title": "",
                "description": "From the tender slices of sashimi to the rich and savory broth of our ramen, every bite at Kokuu is a testament to our dedication to excellence. We understand that dietary preferences and restrictions vary, which is why we offer a diverse range of options to cater to every palate. Vegetarian and gluten-free choices are thoughtfully incorporated into our menu, ensuring that everyone can partake in the joy of our culinary offerings. Thank you for choosing Kokuu . May your experience with us be as nourishing and fulfilling as the arrival of Grain Rain itself.",
            },
            {
                "img": "./img/v1/craftsman1.jpeg",
                "title": "Craftmanship",
                "description": "We take pride in our homemade noodles. Made with carefully selected wheat flour and a secret recipe, our noodles have a unique texture that will tantalize your taste buds.",
            },
            {
                "img": "./img/v1/craftsman2.jpeg",
                "title": "",
                "description": "Another area of our dedication is our Creamy Soup. The broth was meticulously crafted with pork and chicken bones, which are simmered for long hours to extract the full umami flavor, carefully refining it to perfection.",
            },
            {
                "img": "./img/v1/craftsman3.jpeg",
                "title": "",
                "description": "This rich, yet smooth flavor spreads in your mouth, delivering delight to your taste buds with every bite.",
            },
            {
                "img": "./img/v1/craftsman4.jpeg",
                "title": "",
                "description": "We are always committed to the quality of our noodles and soup. The combination of our handcrafted noodles and creamy soup is the perfect combination to compliment each other, together creating the finest bowl of ramen.",
            },
        ],
        "social_media": [
            {
                icon: "facebook",
                link: "https://www.facebook.com/"
            },
            {
                icon: "instagram",
                link: "https://instagram.com/"
            },
        ],
        "contact_us": {
            "phone": 1234567890,
            "address": "343 S Kirkwood Rd, St. Louis, MO 63122",
            "background": "./img/v1/contactus-banner.webp",
            "business_hours": "Sun-Thurs:\t\t11:30 AM - 9:30 PM\nFri-Sat:\t\t\t11:30 AM - 10 PM"
        },
    }
}
export default data
