import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import data from '../../data/locData';

const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['<i  class="fa fa-chevron-left " aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="false"></i>'],
    responsiveClass: true,
    navSpeed: 2000,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 1,
            nav: true
        },
        1000: {
            items: 1,
            nav: true,
            loop: true
        }
    }
};

function SliderGallery() {
    const gallery = data.location.gallery
    const listItemStyle = data.settings
    return (
        <>
            <div id="gallery" className="newgalleryFull displaySection">
                <div className="container">
                    <h2 style={{
                        color: listItemStyle.primaryColor
                    }}>Our Signature</h2>
                    <OwlCarousel id="reviewSlider" className='owl-carousel owl-theme'{...options}>
                        {
                            gallery.map((item, i) => {
                                return (
                                    <div className="item" key={i}>
                                        <div className="leftreviewText">

                                            <div className='galleryFullImages'>
                                                <img className='img-fluid' src={item.original} alt="gallery"/>
                                            </div>

                                        </div>

                                    </div>

                                )
                            })
                        }
                    </OwlCarousel>

                </div>
            </div>
        </>
    )
}

export default SliderGallery