import React, {useEffect} from 'react'
import {Link} from "react-router-dom";
import Nav from './Nav'
import data from '../../data/locData';
import HamburgerMenu from './HamburgerMenu';


function Header() {

    const locData = data.location
    const listItemStyle = data.settings

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    useEffect(() => {
        // scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        let offset = 250;
        if(window.innerWidth <= 820) {
            offset = 50;
        }
        scrollTop >= offset ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };
    return (
        <>
            <header className="header v1_head header-section">
                <div className="container">
                    <div className="rowFlex">
                        <div className="brand">
                            <Link to="#" onClick={() => {
                                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                            }}>
                                <img src={locData.logo} alt="Logo"
                                     className={locData.logo === "" ? "hide" : ""}/>
                                <h2 style={{color: listItemStyle.primaryColor}}>
                                    {locData.logo === "" ? locData.name : ""}
                                </h2>
                            </Link>
                        </div>
                        <Nav/>
                    </div>
                </div>
            </header>
            <div className="mobileMenu">
                <HamburgerMenu/>
            </div>


        </>
    )
}

export default Header