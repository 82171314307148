import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import data from '../../data/locData';

const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ["<i class='fa fa-chevron-right'></i>", "<i class='fa fa-chevron-left'></i>"],
    responsiveClass: true,
    navSpeed: 2000,
    responsive: {
        0: {
            items: 1,
            nav: true
        },
        600: {
            items: 1,
            nav: true
        },
        1000: {
            items: 1,
            nav: true,
            loop: true
        }
    }
};

function Reviews() {
    const reviews = data.location.reviews
    const listItemStyle = data.settings
    if (reviews == null) {
        return ""
    }
    
    return (
        <>
            <div id="reviews" className="review new-templte-revivew displaySection">
                <div className="container">
                    <div className='head' style={{
                        color: listItemStyle.seconderyColor
                    }}>
                        <h2 style={{
                            color: listItemStyle.primaryColor
                        }}>Reviews</h2>
                        <p style={{color: listItemStyle.darkColor}}>that's what our
                            client says </p>
                    </div>
                    <div className="reviewFlex">
                        <div className="imageReview">
                            <img src={reviews.image} alt="Reviews"/>
                        </div>
                        <div className="leftreviewText">
                            <OwlCarousel id="reviewSlider" className='owl-carousel owl-theme'{...options}>
                                {
                                    reviews.contents.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <div className="">
                                                    <p style={{color: listItemStyle.darkColor}}>{item.review}</p>
                                                    <span style={{
                                                        color: listItemStyle.primaryColor
                                                    }}>{item.reviewer}</span>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reviews